'use client'

import { useEffect } from 'react'
import * as Sentry from '@sentry/nextjs'
import GlobalErrorContent from './[locale]/components/GlobalErrorContent'

interface ErrorProps {
  error: Error & { digest?: string }
  reset: () => void
}

export default function GlobalError(props: ErrorProps) {
  const { error, reset } = props

  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <html>
      <body>
        {/* All the texts on this page are hardcoded due to the impossibility of usage translations within this level */}
        <GlobalErrorContent onReload={reset} title='Unexpected error occured' />
      </body>
    </html>
  )
}
