import type { ReactNode } from 'react'
import cnb from 'classnames/bind'
import classes from './styles.module.scss'

const cx = cnb.bind(classes)

type PageProps = {
  children?: ReactNode
  className?: string
  rootClassName?: string
  sticky?: boolean
}

function Page(props: PageProps) {
  const { rootClassName, className, children, sticky } = props

  return (
    <section className={cx('root', { [rootClassName]: rootClassName, sticky })}>
      <div className={cx('content', className, 'mt-paper')}>{children}</div>
    </section>
  )
}

export default Page
